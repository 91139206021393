<template lang="pug">
  AccountComponent
</template>
<script>
import AccountComponent from "@vue/components/Account/AccountComponent"
export default {
  name: "Account",
  components: {
    AccountComponent
  }
}
</script>